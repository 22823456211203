<template>
  <div class="app-navbar">
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand href="#">Can't Touch This</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-navbar-nav class="ml-auto" v-if="user">
        <b-nav-item-dropdown right>
          <template #button-content>
            <em>{{ user }}</em>
          </template>
          <b-dropdown-item
            style="text-align: right"
            href="#"
            @click="logoutUser"
            >Logout</b-dropdown-item
          >

          <b-dropdown-item v-if="hasSubscription" disabled href="#"
            >Push Notifications Enabled</b-dropdown-item
          >
          <b-dropdown-item v-else href="#" @click="enablePush"
            >Enable Push Notifications</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#">Link</b-nav-item>
          <b-nav-item href="#" disabled>Disabled</b-nav-item>
        </b-navbar-nav>



        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              >Search</b-button
            >
          </b-nav-form>

          <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>
            <template #button-content>
              <em>User</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item href="#">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse> -->
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {},
  computed: {
    user: function () {
      return this.$root.user;
    },
    hasSubscription: function () {
      return !!this.$root.subscription;
    },
  },
  methods: {
    enablePush: function () {
      console.log("pushing");
      Notification.requestPermission().then((status) => {
        console.log("Got perm:", status);
        if (status !== "granted") {
          return;
        }

        navigator.serviceWorker.register("/worker.js").then((reg) => {
          console.log("Registered:", reg);
          reg.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey:
                "BOnLDqrf06gMIz9kBYw95nUaYZs6hw1zXDoCoUf1uU-BC4n2dUlk14_1kThbf9cZCKc2PuZO-dTLnnE3DoPWxQA",
            })
            .then((sub) => {
              const jsonSub = sub.toJSON();
              this.axios
                .post(`/api/v1/users/${this.$root.user}:setSubscription`, {
                  subscription_json: JSON.stringify(jsonSub),
                })
                .then(() => {
                  this.$root.subscription = jsonSub;
                })
                .catch((err) => {
                  console.log(err);
                });
            });
        });
      });
    },
    logoutUser: function () {
      this.$root.user = null;
      this.$root.subscription = null;
      this.$router.push("/");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.app-navbar {
  z-index: 20;
  right: 0;
  height: 3.6rem;
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid #eaecef;
}
</style>
