import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import {
  BootstrapVue,
  BootstrapVueIcons
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'




const routes = [{
    path: '/',
    name: 'root',
    redirect: () => {
      return {
        path: '/login'
      }
    },
    meta: {
      anonymous: true,
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('./components/AppDashboard.vue'),
    meta: {
      title: "Login",
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./components/AppLogin.vue'),
    meta: {
      anonymous: true,
      title: "Login",
    }
  }
]

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach(async (to, from, next) => {
  const userLoggedIn = !!localStorage.getItem("username");

  if (userLoggedIn) {
    if (to.matched.some((r) => r.name === 'login')) {
      next('/dashboard');
      return;
    }
    next();
    return;
  }

  if (to.matched.some((r) => !(r.meta && r.meta.anonymous))) {
    // If user isn't logged in, redirect them to login
    next('/login');
    return;
  }

  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.tile = to.meta.title ? to.meta.title + " | Can't Touch This" : "Can't Touch This";
  })
})


new Vue({
  data: function () {
    return {
      get user() {
        return localStorage.getItem("username") || null;
      },
      set user(u) {
        if (u === null) {
          localStorage.removeItem("username");
          return
        }
        localStorage.setItem("username", u);
      },
      get subscription() {
        return localStorage.getItem("subscription") || null;
      },
      set subscription(s) {
        if (s === null) {
          localStorage.removeItem("subscription");
          return
        }
        localStorage.setItem("subscription", s);
      },
    };
  },
  router,
  render: h => h(App),
}).$mount('#app')